export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const background1 = require("../assets/background1.jpeg");
export const background2 = require("../assets/background2.jpeg");
export const sureWhite = require("../assets/sureWhitelogo.png");
export const sureBlue = require("../assets/sureBlueLogo.png");
export const lawLogo = require("../assets/lawFirmLogo.png");
export const AdviceLogo = require("../assets/legalAdviceIcon.png");
export const FirstImg = require("../assets/FirstImg.jpeg");
export const secondImg = require("../assets/secondImg.jpeg");
export const thirdImg = require("../assets/thirdImage.jpeg");
export const icon1 = require("../assets/icon1.png");
export const icon2 = require("../assets/icon2.png");





