import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Paper,
  InputLabel,
  IconButton,
  Avatar,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  TextField,
  Checkbox,
  List,
  ListItem,

} from "@mui/material"
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { Field, Form, Formik } from "formik";
import { sureBlue, sureWhite, thirdImg, icon1, icon2, lawLogo, AdviceLogo, background2, } from "./assets";
import * as Yup from "yup";
import { styled } from "@mui/material/styles"
import { CancelOutlined, CheckCircleOutlineOutlined, RadioButtonUnchecked, Visibility, VisibilityOff, } from "@mui/icons-material";

const webStyle = {
  container: {
    overflowX: "hidden",

  },
  mainGrid: {
    display: "flex",
  },
  titleText: {
    color: "#000000",
    paddingTop: "1rem",
    fontWeight: 700,
    paddingBottom: "2rem",
    fontSize: "24px"
  },
  loginBtn: {
    color: "#00C975",
    fontWeight: 700,
    fontSize: "16px",
    textTransform: "none"
  },
  haveAccountText: {
    fontSize: "16px",
    fontWeight: 400,
    color: '#0F172A'
  },
  haveAccountBox: {
    display: "flex",
    gap: 0.5,
    mt: "25px",
    alignItems: "center"
  },
  haveAccountBox2: {
    textAlign: "center",
    display: "flex",
    alignItems: "center"
  },
  accountTypeText: {
    fontSize: "16px",
    fontWeight: 700
  },
  describeText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000"
  },
  gridBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  nextBtn: {
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 700,
    marginTop: "40px",
    width: "360px", mb: 2,
    py: 1.5,
    bgcolor: "#00C975",
    "&:hover":
    {
      bgcolor: "#00C975",
    }
  },
  carouselGrid:{
     width: "100%", 
     maxHeight: "100vh", 
     overflow: "hidden", 
     borderTopRightRadius: "16px", 
     borderBottomRightRadius: "16px"
  },
  greenSection: {
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  logo: {
    width: "188px",
    height: "107px",
  },
  title: {
    fontSize: "36px",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  subtitle: {
    fontSize: "20px",
    fontWeight: 400,
    color: "#FFFFFF",
  },
  carouselContainer: {
    height: "240px",
    marginTop: "150px",
  },
  testimonialText: {
    fontSize: "18px",
    fontWeight: 400,
    fontStyle: "italic",
    color: "#FFFBEB",
  },
  authorBox: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: "8px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  authorName: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#FFFBEB",
  },
  authorRole: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#FFFBEB",
  },
  carouselDotsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "200px",
  },
  carouselDots: {
    display: "flex",
    marginX: 2,
  },
  gridContainer: {
    minHeight: "100vh",
  },
  logoBox: {
    textAlign: "center",
    marginBottom: "16px",
  },
  titleText1: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#334155",
    textAlign: "center",
    marginBottom: "16px",
  },
  inputLabel: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
  },
  checkbox: {
    color: "#00C975",
  },
  privacyText: {
    color: "black",
  },
  haveAccountBox3: {
    position: "relative",
    textAlign: "center",
    marginTop: "16px",
  },
  haveAccountText1: {
    fontSize: "16px",
    fontWeight:400,
    color: "#0F172A",
  },
  loginBtn1: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#212FE0",
    textTransform: "none",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  logoImage: {
    marginBottom: "80px",
  },
  optionCardText: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#94A3B8",
    textAlign: "center",
  },
  haveAccountBox1: {
    display:"flex", 
    alignItems:"center",
    marginTop: "16px",
  },
  haveAccountText3: {
    fontSize: "14px",
    color: "#334155",
  },
  loginBtn2: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#00C975",
    textTransform: "none",
  },
}

const GreenSection = styled(Paper)<{ bgcolor?: string }>(({ theme, bgcolor }) => ({
  backgroundImage: bgcolor,
  color: "white",
  height: "100%",
  padding: theme.spacing(6),
  borderRadius: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundSize: "cover",
  backgroundPosition: "center",
  transition: "background-color 0.5s ease",
  overflow: "hidden",
}));

const TestimonialCard = styled(Card)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  color: "white",
  borderRadius: "20px",
  padding: "20px"
}))

const WhiteSection = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: "450px",
  margin: "0 auto",
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: "#00A67E",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00A67E",
    },
  },
}))

const OptionCard1 = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: "2px solid",
  borderRadius: "8px",
  height: "120px",
  transition: "all 0.3s ease",
  "&:hover": {
    borderColor: "#FCC279",
  },
}))

const OptionCard2 = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: "2px solid",
  borderRadius: "8px",
  height: "120px",
  transition: "all 0.3s ease",
  "&:hover": {
    borderColor: "#00C975",
  },
}))

const GreenButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: "#212FE0",
  color: "white",
  "&:hover": {
    backgroundColor: "#212FE0",
  },
  "&.Mui-disabled": {
    backgroundColor: "#e0e0e0",
    color: "#a0a0a0",
  },
}))

const CarouselDot = styled("div")<{ active?: boolean }>(({ active }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: active ? "white" : "rgba(255, 255, 255, 0.3)",
  margin: "0 4px",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
}))

const CarouselContainer = styled(Box)({
  position: "relative",
  overflow: "hidden",
  width: "100%",
})

const CarouselSlide = styled(Box)<{ active: boolean; direction: string }>(
  ({ active, direction }) => {
    let translateValue = "translateX(0)";

    if (!active) {
      if (direction === "right") {
        translateValue = "translateX(100%)";
      } else if (direction === "left") {
        translateValue = "translateX(-100%)";
      }
    }
    return {
      position: "absolute",
      top: 5,
      left: 0,
      width: "100%",
      opacity: active ? 1 : 0,
      transform: translateValue,
      transition: "opacity 0.5s ease, transform 0.5s ease",
      zIndex: active ? 1 : 0,
    };
  }
);

const validationSchema = Yup.object({
  fullName: Yup.string().required("Full name is required").min(2, "Name must be at least 2 characters"),
  lastName: Yup.string().required("Last name is required").min(2, "Name must be at least 2 characters"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number must be 10 digits"),
})

const validationSchema2 = Yup.object({
  fullName: Yup.string().required("Full name is required").min(2, "Name must be at least 2 characters"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number must be 10 digits"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
    ),
  agreeToTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
})

const slides = [
  {
    testimonial:
      "Thanks to this app, I connected with clients easily, boosting my caseload and enhancing client satisfaction. Highly recommend it!",
    author: "Harvey Brandon",
    role: "Partner and CEO",
    backgroundImage: `linear-gradient(rgba(236, 180, 60, 0.4), rgba(236, 180, 60, 0.4)), url(${background2.default})`
  },
  {
    testimonial: "Surely has transformed how our firm acquires new clients. The platform is intuitive and effective.",
    author: "Sarah Johnson",
    role: "Managing Partner",
    backgroundImage: `url(${thirdImg.default})`
  },
  {
    testimonial:
      "The ROI we've seen from Surely has been exceptional. Our client base has grown 30% in just three months.",
    author: "Michael Chen",
    role: "Legal Director",
    backgroundImage: `linear-gradient(rgba(46, 66, 165, 0.6), rgba(46, 66, 165, 0.6)), url(${thirdImg.default})`
  },
];

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  getValidationIcon = (isValid: boolean): JSX.Element => {
    if (this.state.password === "") {
      return this.getDefaultIcon();
    }
    return this.getIconBasedOnValidation(isValid);
  };

  getDefaultIcon = (): JSX.Element => (
    <RadioButtonUnchecked sx={{ color: "text.disabled" }} />
  );

  getIconBasedOnValidation = (isValid: boolean): JSX.Element => {
    if (isValid) {
      return <CheckCircleOutlineOutlined color="success" />;
    }
    if (
      this.state.validations.minLength ||
      this.state.validations.hasUppercase ||
      this.state.validations.hasLowercase
    ) {
      return <CancelOutlined color="error" />;
    }
    return this.getDefaultIcon();
  };

  crousalComponent(currentBgColor: any) {
    return (
      <Grid style={webStyle.carouselGrid}
        sx={{
          maxWidth: { lg: "40%", xs: "100%" }
        }}
      >
        <GreenSection bgcolor={currentBgColor}>
          <Box sx={webStyle.greenSection}>
            <img style={webStyle.logo} src={sureWhite.default} />
            <Typography style={webStyle.title}>Welcome to Surely</Typography>
            <Typography style={webStyle.subtitle}>
              Connect with potential clients effortlessly! Sign up and elevate your law firm's reach in the legal marketplace today!
            </Typography>
            <Box>
              <CarouselContainer sx={webStyle.carouselContainer}>
                {slides.map((slide, index) => (
                  <CarouselSlide key={index} active={index === this.state.activeStep} direction={this.state.direction} data-test-id='carousel-slide'>
                    <TestimonialCard>
                      <CardContent>
                        <Typography style={webStyle.testimonialText} variant="body1" paragraph>
                          "{slide.testimonial}"
                        </Typography>
                        <Box sx={webStyle.authorBox}>
                          <Avatar sx={webStyle.avatar}>
                            {slide.author.charAt(0)}
                          </Avatar>
                          <Box>
                            <Typography style={webStyle.authorName} variant="subtitle2">{slide.author}</Typography>
                            <Typography style={webStyle.authorRole} variant="caption">{slide.role}</Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </TestimonialCard>
                  </CarouselSlide>
                ))}
              </CarouselContainer>
              <Box sx={webStyle.carouselDotsContainer}>
                <Box sx={webStyle.carouselDots}>
                  {slides.map((_, index) => (
                    <CarouselDot
                      data-test-id='carousel'
                      key={index}
                      active={index === this.state.activeStep}
                      onClick={() => this.handleDotClick(index)}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </GreenSection>
      </Grid>
    )
  }

  passwordSection = () => {
    return (
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Password
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          type={this.state.showPassword ? "text" : "password"}
          placeholder="Your password"
          value={this.state.password}
          onChange={this.handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={this.handleClickShowPassword} edge="end">
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            sx: { borderRadius: 2 },
          }}
        />
      </Box>
    )
  }

  confirmPasswordSection = () => {
    return (
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Confirm Password
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          type={this.state.showConfirmPassword ? "text" : "password"}
          placeholder="Your password"
          value={this.state.confirmPassword}
          onChange={this.handleConfirmPasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={this.handleClickShowConfirmPassword}
                  edge="end"
                >
                  {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            sx: { borderRadius: 2 },
          }}
        />
      </Box>
    )
  }

  nextButtonSection = () => {
    return (
      <Button
        disabled={this.state.selectedAccountType === ''}
        onClick={this.nextStep} data-test-id='next-step2'
        variant="contained"
        sx={{ textTransform: 'none' }}
        style={{
          ...webStyle.nextBtn,
          backgroundColor: this.state.selectedAccountType === '' ? '#F1F5F9' : webStyle.nextBtn.bgcolor,
          color: this.state.selectedAccountType === '' ? '#64748B' : webStyle.nextBtn.color
        }}
      >Next</Button>
    )
  }

  seekingClientSection = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" sx={webStyle.gridContainer}>
      <Grid item xs={12} md={6} lg={12}>
        <WhiteSection>
          <Box sx={webStyle.logoBox}>
            <img src={sureBlue.default} alt="Surely Logo" />
          </Box>
          <Typography sx={webStyle.titleText1}>Sign up to Surely</Typography>

          <Formik
            initialValues={{ fullName: "", email: "", phoneNumber: "", password: "", agreeToTerms: false }}
            validationSchema={validationSchema2}
            onSubmit={(values) => {
              this.goToOtpVerification();
            }}
            data-test-id="formik"
          >
            {({ errors, touched, values, handleChange }) => (
              <>
                <Form style={{ width: "100%" }}>
                  <Box sx={{ mb: 1 }}>
                    <Typography sx={webStyle.inputLabel}>Full name</Typography>
                    <Field
                      as={StyledTextField}
                      fullWidth
                      name="fullName"
                      placeholder="Your full name"
                      value={values.fullName}
                      onChange={handleChange}
                      error={Boolean(errors.fullName)}
                      helperText={this.handleCondition(touched.fullName, errors.fullName, "")}
                    />
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography sx={webStyle.inputLabel}>Email</Typography>
                    <Field
                      as={StyledTextField}
                      fullWidth
                      name="email"
                      type="email"
                      placeholder="Your email"
                      value={values.email}
                      onChange={handleChange}
                      error={Boolean(errors.email)}
                      helperText={this.handleCondition(touched.email, errors.email, "")}
                    />
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography sx={webStyle.inputLabel}>Phone number</Typography>
                    <Field
                      as={StyledTextField}
                      fullWidth
                      name="phoneNumber"
                      placeholder="Your phone number"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      error={Boolean(errors.phoneNumber)}
                      helperText={this.handleCondition(touched.phoneNumber, errors.phoneNumber, "")}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+912</InputAdornment>,
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography sx={webStyle.inputLabel}>Password</Typography>
                    <Field
                      as={StyledTextField}
                      fullWidth
                      name="password"
                      type={this.handleCondition(this.state.showPassword, "text", "password")}
                      placeholder="Your password"
                      value={values.password}
                      onChange={handleChange}
                      error={Boolean(errors.password)}
                      helperText={this.handleCondition(touched.password, errors.password, "")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={this.handleClickShowPassword} edge="end" data-test-id="show-password">
                              {this.handleCondition(this.state.showPassword, <Visibility />, <VisibilityOff />)}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <FormControlLabel
                      control={<Field as={Checkbox} name="agreeToTerms" sx={webStyle.checkbox} />}
                      label={
                        <Typography variant="body2">
                          I agree with{" "}
                          <a href="#" style={webStyle.privacyText}>
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a href="#" style={webStyle.privacyText}>
                            Terms and Conditions
                          </a>
                        </Typography>
                      }
                    />
                    {errors.agreeToTerms && touched.agreeToTerms && (
                      <Typography variant="body2" color="error">
                        {errors.agreeToTerms}
                      </Typography>
                    )}
                  </Box>

                  <GreenButton fullWidth variant="contained" size="large" type="submit">
                    Sign up
                  </GreenButton>
                </Form>
              </>
            )}
          </Formik>

          <Box sx={webStyle.haveAccountBox3}>
            <Typography variant="body2" sx={webStyle.haveAccountText1}>
              Already have an account?
            </Typography>
            <Button data-test-id="login-btn" onClick={this.goToLoginPage} sx={webStyle.loginBtn1}>
              Log in
            </Button>
          </Box>
        </WhiteSection>
      </Grid>
    </Grid>
  );
};


  lawFirmSection = () => {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "100vh" }}>
        <Grid item xs={12} md={6} lg={12}>
          <WhiteSection>
            <Box sx={{ textAlign: "center", mb: 2 }}>
              <img src={sureBlue.default} alt="Surely Logo" />
            </Box>
            <Typography sx={webStyle.titleText}>
              Sign up to Surely
            </Typography>
            <Formik
              initialValues={{
                lastName: "",
                email: "",
                fullName: "",
                phoneNumber: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.nextStep();
              }}

              data-test-id="formik2"
            >
              {({ errors, touched, values, handleChange }) => (
                <>
                  <Form style={{ width: "100%" }} >
                    <Box sx={{ mb: 1 }}>
                      <Typography style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>First name</Typography>
                      <Field
                        as={StyledTextField}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(errors.fullName)}
                        name="fullName"
                        value={values.fullName}
                        placeholder="Your full name"
                        helperText={this.handleCondition(touched.fullName, errors.fullName, "")}
                      />
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <Typography style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Last name</Typography>
                      <Field
                        fullWidth
                        as={StyledTextField}
                        value={values.lastName}
                        onChange={handleChange}
                        error={Boolean(errors.lastName)}
                        name="lastName"
                        placeholder="Your full name"
                        helperText={this.handleCondition(touched.lastName, errors.lastName, "")}
                      />
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <Typography style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Email</Typography>
                      <Field
                        as={StyledTextField}
                        fullWidth
                        name="email"
                        type="email"
                        placeholder="Your email"
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        value={values.email}
                        helperText={this.handleCondition(touched.email, errors.email, "")}
                      />
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <Typography style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Phone number</Typography>
                      <Field
                        as={StyledTextField}
                        placeholder="Your phone number"
                        fullWidth
                        name="phoneNumber"
                        onChange={handleChange}
                        error={Boolean(errors.phoneNumber)}
                        value={values.phoneNumber}
                        helperText={this.handleCondition(touched.phoneNumber, errors.phoneNumber, "")}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +912
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <GreenButton data-test-id='nextStep3' fullWidth variant="contained" size="large" type="submit">
                      Sign up
                    </GreenButton>
                  </Form>
                </>
              )}
            </Formik>

            <Box sx={webStyle.haveAccountBox2}>
              <Typography sx={webStyle.haveAccountText}>
                Already have an account?
              </Typography>
              <Button data-test-id="login-btn" onClick={this.goToLoginPage} sx={webStyle.loginBtn}>
                Log in
              </Button>
            </Box>
          </WhiteSection>
        </Grid>
      </Grid>
    )
  }

  render() {
    const { activeStep } = this.state
    const currentBgColor = slides[activeStep].backgroundImage
    return (
      <Container maxWidth={false} disableGutters sx={webStyle.container}>
        <Grid sx={{
          flexDirection: { xs: "column", md: "row" },
        }}
          style={webStyle.mainGrid}>
          {this.crousalComponent(currentBgColor)}
          <Grid item xs={12} md={6} style={{ width: "100%", marginBottom: "5rem" }}>
            {this.state.step === 1 && (
              <WhiteSection>
                <Box sx={webStyle.logoContainer}>
                  <img src={sureBlue.default} alt="Logo" style={webStyle.logoImage} />
                </Box>
                <Typography variant="h4" component="h1" gutterBottom>
                  Welcome to Surely
                </Typography>
                <Typography variant="body1" paragraph>
                  Please select an account type, and sign up
                </Typography>
                <FormControl component="fieldset" sx={{ width: "100%", my: 3 }}>
                  <RadioGroup
                    data-test-id="accountType1"
                    aria-label="account-type"
                    name="account-type"
                    value={this.state.selectedOption}
                    onChange={(e) => this.handleOptionSelect(e.target.value)}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <OptionCard1
                          data-test-id="option1"
                          elevation={this.state.selectedOption === "law-firm" ? 3 : 0}
                          sx={{
                            borderColor: this.state.selectedOption === "law-firm" ? "#FCC279" : "#e0e0e0",
                          }}
                          onClick={() => this.handleOptionSelect("law-firm")}
                        >
                          <img src={lawLogo.default} alt="Logo" />
                          <Typography variant="subtitle1" sx={webStyle.optionCardText}>
                            Lawyer <br />
                            or Law firm
                          </Typography>
                        </OptionCard1>
                      </Grid>
                      <Grid item xs={6}>
                        <OptionCard2
                          data-test-id="option2"
                          elevation={this.state.selectedOption === "seeking-advice" ? 3 : 0}
                          sx={{
                            borderColor: this.state.selectedOption === "seeking-advice" ? "#00C975" : "#e0e0e0",
                          }}
                          onClick={() => this.handleOptionSelect("seeking-advice")}
                        >
                          <img src={AdviceLogo.default} alt="Logo" />
                          <Typography variant="subtitle1" sx={webStyle.optionCardText}>
                            Seeking Legal Advice
                          </Typography>
                        </OptionCard2>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
                <GreenButton
                  disabled={this.state.selectedOption === ""}
                  fullWidth
                  variant="contained"
                  size="large"
                  data-test-id="next-step"
                  onClick={this.nextStep}
                >
                  Next
                </GreenButton>
                <Box sx={webStyle.haveAccountBox1}>
                  <Typography sx={webStyle.haveAccountText1}>Already have an account?</Typography>
                  <Button onClick={this.goToLoginPage} data-test-id="goToLoginBtn" sx={webStyle.loginBtn1}>
                    Log in
                  </Button>
                </Box>
              </WhiteSection>
            )}
            {this.state.step === 2 && this.state.selectedOption === "seeking-advice" && (
              <Box sx={{ height: "100vh" }}>
                <Grid item
                  xs={12}
                  sx={{
                    width: "-moz-available",
                    display: "flex",
                    justifyContent: "center",
                    bgcolor: "white",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  md={6}
                >
                  <Grid sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Box sx={{ width: "100%", maxWidth: "400px" }}>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <img src={sureBlue.default} alt="Surely Logo" style={{ paddingTop: "50px", paddingBottom: "120px" }} />
                        </Box>
                        <Typography style={webStyle.titleText}>
                          Sign Up to Surely
                        </Typography>

                        <Typography style={{ fontSize: "16px", fontWeight: 400, color: "#000000" }}>
                          What describe you the best ?
                        </Typography>
                        <Box>

                          <Box style={{ paddingTop: "2rem" }}>
                            <Typography style={webStyle.accountTypeText}>Account Type</Typography>
                            <FormControl fullWidth>
                              {this.state.selectedAccountType === '' && (
                                <InputLabel shrink={false}>Select an account type</InputLabel>
                              )}
                              <Select
                                data-test-id='select1'
                                value={this.state.selectedAccountType}
                                displayEmpty
                                style={{ width: "360px" }}
                                onChange={this.handleChange}
                              >
                                <MenuItem
                                  value="Individual"
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#00C9751A",
                                      color: "black",
                                    },
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "row"
                                    }}>
                                    <ListItemIcon>
                                      <img src={icon1.default} />
                                    </ListItemIcon>
                                    <ListItemText primary="Individual" />
                                  </Box>
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#00C9751A",
                                      color: "black",
                                    },
                                  }}
                                  value="Business"
                                >
                                  <Box style={{ display: "flex", flexDirection: "row" }}>
                                    <ListItemIcon>
                                      <img src={icon2.default} />
                                    </ListItemIcon>
                                    <ListItemText primary="Business" />
                                  </Box>
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box>
                            {this.nextButtonSection()}
                            <Box sx={webStyle.haveAccountBox}>
                              <Typography sx={webStyle.haveAccountText3}> Already have an account?  </Typography>
                              <Button onClick={this.goToLoginPage} data-test-id="loginPageBtn" sx={webStyle.loginBtn2}> Log in </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

              </Box>
            )}
            {this.state.step === 2 && this.state.selectedOption === "law-firm" && (
              <Box sx={{ height: "100vh" }}>
                <Grid item
                  xs={12}
                  md={6}
                  sx={{
                    width: "-moz-available",
                    bgcolor: "white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid style={webStyle.gridBox}>
                    <Box sx={{ width: "100%", maxWidth: "400px" }}>
                      <Box>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <img src={sureBlue.default} alt="Surely Logo" style={{ paddingTop: "50px", paddingBottom: "120px" }} />
                        </Box>
                        <Typography style={webStyle.titleText}>
                          Sign Up to Surely
                        </Typography>
                        <Typography style={webStyle.describeText}>
                          What describe you the best ?
                        </Typography>
                        <Box>

                          <Box style={{ paddingTop: "2rem" }}>
                            <Typography
                              style={webStyle.accountTypeText}>
                              Account Type
                            </Typography>
                            <FormControl fullWidth>
                              {this.state.selectedAccountType === '' && (<InputLabel shrink={false}>Select an account type</InputLabel>)}
                              <Select data-test-id='select1'
                                style={{ width: "360px" }}
                                value={this.state.selectedAccountType}
                                onChange={this.handleChange}
                                displayEmpty
                              >
                                <MenuItem
                                  value="individual_lawyer"
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#00C9751A",
                                      color: "black",
                                    },
                                  }}
                                >
                                  <Box style={{ display: "flex", flexDirection: "row" }}>
                                    <ListItemIcon> <img src={icon1.default} /></ListItemIcon>
                                    <ListItemText primary="Individual lawyer" />
                                  </Box>
                                </MenuItem>
                                <MenuItem
                                  value="Law firm"
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#00C9751A", color: "black",
                                    },
                                  }}
                                >
                                  <Box style={{ display: "flex", flexDirection: "row" }}>
                                    <ListItemIcon>
                                      <img src={icon2.default} />
                                    </ListItemIcon>
                                    <ListItemText primary="Law firm" />
                                  </Box>
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box>
                            <Button disabled={this.state.selectedAccountType === ''}
                              onClick={this.nextStep} data-test-id='next-step2'
                              sx={{ textTransform: 'none' }} variant="contained"
                              style={{
                                ...webStyle.nextBtn,
                                color: this.state.selectedAccountType === '' ? '#64748B' : webStyle.nextBtn.color,
                                backgroundColor: this.state.selectedAccountType === '' ? '#F1F5F9' : webStyle.nextBtn.bgcolor,
                              }}
                            >Next</Button>
                            <Box sx={webStyle.haveAccountBox}>
                              <Typography sx={webStyle.haveAccountText}> Already have an account?</Typography>
                              <Button data-test-id="loginPageBtn" onClick={this.goToLoginPage} sx={webStyle.loginBtn}>Log in
                              </Button>
                            </Box> </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {this.state.step === 3 &&
              (this.state.selectedOption === "seeking-advice" ? (
                <>
                  {this.seekingClientSection()}
                </>
              ) : (
                <>
                  {this.lawFirmSection()}
                </>
              ))
            }

            {this.state.step === 4 && (
              <>
                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "100vh" }}>
                  <Grid item xs={12} md={6} lg={12}>
                    <WhiteSection>
                      <Box sx={{ textAlign: "center", mb: 2 }}>
                        <img src={sureBlue.default} alt="Surely Logo" />
                      </Box>
                      <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#000000", paddingTop: "1rem", paddingBottom: "2rem" }}>
                        Password setup
                      </Typography>
                      {this.passwordSection()}
                      {this.confirmPasswordSection()}
                      <List dense disablePadding>
                        <ListItem disableGutters>
                          <ListItemIcon sx={{ minWidth: 36 }}>{this.getValidationIcon(this.state.validations.minLength)}</ListItemIcon>
                          <ListItemText primary="at least 8 characters" />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemIcon sx={{ minWidth: 36 }}>{this.getValidationIcon(this.state.validations.hasUppercase)}</ListItemIcon>
                          <ListItemText primary="at least one capital letter" />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemIcon sx={{ minWidth: 36 }}>{this.getValidationIcon(this.state.validations.hasLowercase)}</ListItemIcon>
                          <ListItemText primary="at least one lowercase letter" />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemIcon sx={{ minWidth: 36 }}>{this.getValidationIcon(this.state.validations.hasNumber)}</ListItemIcon>
                          <ListItemText primary="1 number" />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemIcon sx={{ minWidth: 36 }}>{this.getValidationIcon(this.state.validations.hasSpecial)}</ListItemIcon>
                          <ListItemText primary="1 special character" />
                        </ListItem>
                      </List>
                      <Box sx={{ mb: 2 }}>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                          <Checkbox style={{ color: "#00C975" }} data-test-id='checkbox' checked={this.state.agreeToTerms} onChange={this.handleCheckboxChange} />
                          <Typography variant="body2">
                            I agree with <a style={{ color: "black" }} href="#">Privacy Policy</a> and <a style={{ color: "black" }} href="#">Terms and Conditions</a>
                          </Typography>
                        </Box>
                        <GreenButton onClick={this.nextStep} fullWidth variant="contained" size="large" type="submit">
                          Sign up
                        </GreenButton>
                      </Box>
                      <Box sx={webStyle.haveAccountBox2}>
                        <Typography variant="body2" sx={webStyle.haveAccountText}>
                          Already have an account?
                        </Typography>
                        <Button data-test-id="login-btn" onClick={this.goToLoginPage} sx={webStyle.loginBtn}>
                          Log in
                        </Button>

                      </Box>

                    </WhiteSection>
                  </Grid>
                </Grid>
              </>
            )}
            <Box sx={{
              padding: "10px",
              zIndex: 1000,
              display:"flex",
              justifyContent:"end",
              position: "relative",
              bottom:{lg:"7rem",md:"7rem", xs:"1rem"} ,
              right:{lg:"2rem", xs:"1rem"}

            }}>
              <FormControl variant="outlined" size="small">
                <Select
                  data-test-id="language-select"
                  sx={{
                    width: "143px",
                    height: "56px",
                    fontSize: "0.875rem", 
                  }}
                  value={this.state.selectedLanguage}
                  onChange={this.handleLanguageChange}
                  displayEmpty
                >
                  <MenuItem
                    value="en"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#00C9751A",
                        color: "black",
                      },
                    }}
                  >
                    <Box sx={{ flexDirection: "row", alignItems: "center" }}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="English" sx={{ ml: "10px" }} />
                    </Box>
                  </MenuItem>
                  <MenuItem
                    value="ar"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#00C9751A",
                        color: "black",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary="العربية" />
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

          </Grid>
        </Grid>

      </Container >
    );
  }
}

