export const backgroundImg = require("../assets/backgroundImg.jpeg");
export const logo = require("../assets/Logo.png");
export const avatar = require("../assets/Avatar.jpeg")
export const logo_2 = require("../assets/logoes-02.png")
export const uploadFile = require("../assets/textinput_.jpg")
export const view_id_card_hat = require("../assets/view_id_card_hat.jpg")
export const closeImg = require("../assets/image_.png")
export const celebrate = require("../assets/group_confetti.jpg")





