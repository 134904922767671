// Customizable Area Start
import React from "react";
// Customizable Area Start
import { MenuItem, Typography, Box, Select, FormControl, InputLabel, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import MultipageFormsController, {
  Props,

} from "./MultipageFormsController";
import { backgroundImg, celebrate, closeImg, logo, logo_2, view_id_card_hat } from "./assets";
// Customizable Area End

export default class MultipageForms extends MultipageFormsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  practiceAreas = [
    "Business Law",
    "Criminal Law",
    "Employment Law",
    "Family Law",
    "Intellectual Property",
  ];

  getSuccessMessage = () => {
    return (
      <Box>
        {this.state.successMessage ? (
          <Box>
            {this.state.explore ? (
              <Box sx={{ py: "4rem" }}>
                <Box sx={{ display: "flex", justifyContent: "center", height: 100, position: "relative", bottom: 30 }}>
                  <img src={logo_2.default} alt="Surely Logo" style={{ width: 150, height: 55, margin: "auto" }} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box sx={{ backgroundColor: "#E2E8F0", display: "flex", alignItems: "center", mb: 2 }}>
                      <Box sx={{ maxWidth: 77, backgroundColor: "#00C975", flexGrow: 1, height: 7, borderRadius: 2 }} />
                    </Box>
                    <Box>
                      <Typography variant="h5" fontWeight="bold" gutterBottom>
                        Practice details
                      </Typography>
                      <Typography variant="body1" color="text.secondary" gutterBottom>
                        Tell us about your expertise
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ maxWidth: 458 }}>
                    <Typography sx={{ color: "#334155", fontWeight: 700, fontSize: 16 }}>
                      Select your practice area (Select all that apply)
                    </Typography>
                    <Box sx={{ border: 1, borderColor: "#E2E8F0", borderRadius: 2, p: 2, overflowY: "auto" }}>
                      <FormGroup>
                        {this.practiceAreas.map((area, index) => (
                          <FormControlLabel
                            key={index}
                            control={<Checkbox sx={{ '&.Mui-checked': { color: "green" } }} />}
                            label={area}
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "16px" }}>
                    <Box>
                      <button
                        style={{
                          backgroundColor: "#00C975", padding: "10px 16px", fontSize: "1rem", border: "none",
                          marginTop: "1rem", borderRadius: "8px", color: "#FFFFFF",
                          fontWeight: 700, font: "16px", width: "221px", height: "56px"
                        }}>
                        Back
                      </button>
                    </Box>
                    <Box>
                      <button
                        style={{
                          backgroundColor: "#00C975", padding: "10px 16px", fontSize: "1rem", border: "none",
                          marginTop: "1rem", borderRadius: "8px", color: "#FFFFFF",
                          fontWeight: 700, font: "16px", width: "221px", height: "56px"
                        }}>
                        Next
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ paddingY: "4rem" }}>
                <Box sx={{ display: "flex", justifyContent: "center", height: "100px", position: "relative", bottom: "30px" }}>
                  <img src={logo_2.default} alt="Surely Logo" style={{ width: "150px", height: "55px", margin: "auto" }} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "42px" }} >
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "42px" }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img src={celebrate.default} alt="" />
                    </Box>
                    <Box>
                      <Typography variant="h5" fontWeight="bold" gutterBottom>
                        Identification success
                      </Typography>
                      <Typography variant="body1" color="text.secondary" gutterBottom>
                        Your account is ready, start exploring surely app now!
                      </Typography>
                    </Box>
                  </Box>
                  <button
                    onClick={this.handleExplore}
                    data-test-id="explorebtn"
                    style={{
                      backgroundColor: "#00C975", padding: "10px 16px", fontSize: "1rem", border: "none",
                      marginTop: "1rem", borderRadius: "8px", color: "#FFFFFF",
                      fontWeight: 700, font: "16px"
                    }}>
                    Explore app
                  </button>
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={{ paddingY: "4rem" }}>
            <Box sx={{ display: "flex", justifyContent: "center", height: "100px", position: "relative", bottom: "30px" }}>
              <img src={logo_2.default} alt="Surely Logo" style={{ width: "150px", height: "55px", margin: "auto" }} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "42px" }} >
              <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <Box sx={{ backgroundColor: "#E2E8F0" }} display="flex" alignItems="center" mb={2} >
                  <Box style={{ maxWidth: "77px", backgroundColor: "#00C975" }} flexGrow={1} height={7} borderRadius={2} />
                </Box>
                <Box>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Identification
                  </Typography>
                  <Typography variant="body1" color="text.secondary" gutterBottom>
                    Identify yourself and start getting the legal advice you need
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <Box>
                  <Box >
                    <Typography sx={{ fontWeight: 700, fontSize: "16px", color: "#334155" }}>Upload National ID</Typography>
                  </Box>
                  <Box sx={{ backgroundColor: "#00C975", width: "200px", height: "2px", borderRadius: "6px" }}></Box>
                  <Box sx={{ border: "1px solid #E2E8F0", borderRadius: "6px", display: "flex", padding: "12px", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ display: "flex", gap: "10px", }}>
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#0D176C33", borderRadius: "6px", width: "42px", height: "42px" }}>
                        <img style={{ objectFit: "cover" }} src={view_id_card_hat.default} alt="img" />
                      </Box>
                      <Box sx={{ fontSize: "12px", fontWeight: 400 }}>
                        <Typography sx={{ color: "#0F172A" }}>id scan march04-2025 new.Jpeg</Typography>
                        <Typography sx={{ color: "#64748B", }}>2s left</Typography>
                      </Box>
                    </Box>
                    <Box><img height={18} width={18} src={closeImg.default} alt="close" /></Box>
                  </Box>
                </Box>

                <Box>
                  <Box >
                    <Typography sx={{ fontWeight: 700, fontSize: "16px", color: "#334155" }}>Upload Passport</Typography>
                  </Box>
                  <Box sx={{ backgroundColor: "#00C975", width: "200px", height: "2px", borderRadius: "6px" }}></Box>
                  <Box sx={{ border: "1px solid #E2E8F0", borderRadius: "6px", display: "flex", padding: "12px", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ display: "flex", gap: "10px", }}>
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#0D176C33", borderRadius: "6px", width: "42px", height: "42px" }}>
                        <img style={{ objectFit: "cover" }} src={view_id_card_hat.default} alt="img" />
                      </Box>
                      <Box sx={{ fontSize: "12px", fontWeight: 400 }}>
                        <Typography sx={{ color: "#0F172A" }}>passport photo march04-2025 new.Jpeg</Typography>
                        <Typography sx={{ color: "#64748B", }}>2s left</Typography>
                      </Box>
                    </Box>
                    <Box><img height={18} width={18} src={closeImg.default} alt="close" /></Box>
                  </Box>
                </Box>

                <Box>
                  <Box >
                    <Typography sx={{ fontWeight: 700, fontSize: "16px", color: "#334155" }}>Upload iqama/residency permit</Typography>
                  </Box>
                  <Box sx={{ backgroundColor: "#00C975", width: "200px", height: "2px", borderRadius: "6px" }}></Box>
                  <Box sx={{ border: "1px solid #E2E8F0", borderRadius: "6px", display: "flex", padding: "12px", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ display: "flex", gap: "10px", }}>
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#0D176C33", borderRadius: "6px", width: "42px", height: "42px" }}>
                        <img style={{ objectFit: "cover" }} src={view_id_card_hat.default} alt="img" />
                      </Box>
                      <Box sx={{ fontSize: "12px", fontWeight: 400 }}>
                        <Typography sx={{ color: "#0F172A" }}>Residency scanned.Jpeg</Typography>
                        <Typography sx={{ color: "#64748B", }}>2s left</Typography>
                      </Box>
                    </Box>
                    <Box><img height={18} width={18} src={closeImg.default} alt="close" /></Box>
                  </Box>
                </Box>
              </Box>

              <button
                onClick={this.handleSuccessClick}
                data-test-id="successClick"
                style={{
                  backgroundColor: "#00C975", padding: "10px 16px", fontSize: "1rem", border: "none",
                  marginTop: "1rem", borderRadius: "8px", color: "#FFFFFF",
                  fontWeight: 700, font: "16px"
                }}>
                Next
              </button>
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  render() {
    return (
      // Customizable Area Start
      <Box sx={{ display: "flex", height: "100vh", width: "auto", flexDirection: { lx: "row", lg: "row", md: "row", sm: "column", xs: "column" } }}>
        {/* Left Side */}
        <Box
          sx={{
            flex: 1,
            maxWidth: { md: "900px", lg: "600px" },
            boxSizing: "border-box",
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
            backgroundImage: `url(${backgroundImg.default})`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "7rem",
            paddingTop: { xs: "40px", md: "91px" },
            paddingBottom: { xs: "40px", md: "91px" },
            paddingLeft: { xs: "40px", md: "64px" },
            paddingRight: { xs: "40px", md: "64px" },
            width: "auto",
          }}
        >
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem"
          }}>
            <img src={logo.default} alt="Surely Logo" style={{ width: "188px", height: "107px", marginBottom: "1rem" }} />
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 700, mb: 2, color: "#FFFFFF", fontSize: "36px", lineHeight: "44px" }}>Welcome to Surely</Typography>
              <Typography sx={{ color: "#FFFFFF", fontSize: "20px", fontWeight: 400 }}>
                Finding the right help has never been easier! SurelyAI connects you with the most experienced attorneys
                precisely when you need them most.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              background: "#16686C",
              padding: { xs: "15px", md: "32px" },
              borderRadius: "24px",
              marginTop: "2rem",
              height: "218px",
              gap: "24px",
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
              maxWidth: { md: "650px", lg: "472px" },
              minWidth: "300px",
            }}
          >
            {this.state.testimonials.length > 0 && (
              <>
                <Typography fontStyle="italic" fontSize="1.1rem" color="#ECFDF5">
                  {this.state.testimonials[this.state.selectedIndex]?.text}
                </Typography>
                <Box sx={{ display: "flex", gap: "14px" }}>
                  <img style={{ objectFit: "cover", width: "38px", height: "38px", borderRadius: "100%", }}
                    src={this.state.testimonials[this.state.selectedIndex]?.image.avatar.default} alt="Avatar" />
                  <Box sx={{ color: "#ECFDF5" }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>{this.state.testimonials[this.state.selectedIndex]?.name}</Typography>
                    <Typography variant="body2">{this.state.testimonials[this.state.selectedIndex]?.Occupation}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box sx={{ position: "relative", bottom: "5rem", display: 'flex', width: "auto", gap: "16px", marginX: "auto", justifyContent: "center", alignItems: "center" }}>
            {this.state.testimonials.map((_, index) => (
              <button
                data-test-id="slidBtn"
                style={{
                  border: "none",
                  borderRadius: "100%",
                  height: this.state.selectedIndex === index ? "12px" : "8px",
                  width: this.state.selectedIndex === index ? "12px" : "8px",
                  backgroundColor: this.state.selectedIndex === index ? "#047857" : "#D9D9D9"
                }}
                key={index}
                onClick={() => this.setState({ selectedIndex: index })}
              >
              </button>
            ))}
          </Box>
        </Box>

        {/* Right Side */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "2rem", md: "3rem" },
            background: "white",
            boxSizing: "border-box"
          }}
        >
          {this.state.showIdentifyUI ? (
            <Box>
              {this.getSuccessMessage()}
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "600px", }}>
              <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "6rem" }}>
                <img src={logo_2.default} alt="Surely Logo" style={{ width: "150px", height: "55px", margin: "auto" }} />
                <Box sx={{ textAlign: "left", width: "100%", maxWidth: "400px" }}>
                  <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>Setup your Surely account</Typography>
                  <Typography sx={{ marginBottom: "2rem" }}>
                    Your account has been verified. Please complete the setup of your account, and select your country and your
                    city.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: "4px", fontWeight: 700 }}>
                  <Typography sx={{ fontWeight: 700 }}> Country</Typography>
                  <FormControl fullWidth>
                    {this.state.selectedCountry === '' && (
                      <InputLabel style={{ color: "#94A3B8" }} shrink={false}>Country</InputLabel>
                    )}
                    <Select
                      value={this.state.selectedCountry}
                      onChange={this.handleCountryChange}
                      displayEmpty
                      data-test-id="counterTEST"
                    >{this.state.countryData?.data?.countries?.map((val: any) => (
                      <MenuItem key={val.id} value={val.name}>
                        {val.name}
                      </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: "4px" }}>
                  <Typography sx={{ fontWeight: 700 }}> City</Typography>
                  <FormControl fullWidth>
                    {this.state.selectedCity === '' && (
                      <InputLabel style={{ color: "#94A3B8" }} shrink={false}>city</InputLabel>
                    )}
                    <Select
                      value={this.state.selectedCity}
                      onChange={this.handleCityChange}
                      displayEmpty
                      data-test-id="cityTEST"
                    >
                      {this.state.countryData?.data?.cities?.map((val: any) => (
                        <MenuItem key={val.id} value={val.name}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", marginTop: "16px" }}>
                <Typography sx={{ fontWeight: 700 }}> Years in practice</Typography>
                <FormControl fullWidth>
                  {this.state.selectedyear === '' && (
                    <InputLabel style={{ color: "#94A3B8" }} shrink={false}>Select number of years</InputLabel>
                  )}
                  <Select
                    value={this.state.selectedyear}
                    onChange={this.handleYearChange}
                    displayEmpty
                    data-test-id="yearTEST"
                  >
                    {this.state.countryData?.data?.years_in_practice?.map((val: any) => (
                      <MenuItem key={val.id} value={val.name}>
                        {val.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <button
                data-test-id="nextBtn"
                onClick={this.handleNextClick}
                style={{
                  backgroundColor: "#00C975", padding: "10px 16px", fontSize: "1rem", border: "none",
                  marginTop: "1rem", borderRadius: "8px", color: "#FFFFFF",
                  fontWeight: 700, font: "16px"
                }}>
                Next
              </button>
            </Box>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area End
