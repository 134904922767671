import React from "react";

// Customizable Area Start
import { StyleSheet } from "react-native";
import { backgroundImg1, blueSurelyImg, whiteSurelyImg } from "./assets";
import { Box, FormControlLabel, Grid, TextField ,Checkbox,Button,Typography, InputAdornment, IconButton} from "@mui/material";
import {Visibility, VisibilityOff,} from "@mui/icons-material";

import * as Yup from "yup";
import { Formik } from "formik";

const webStyle = {
  root: {
    height: '100vh',
  },
  textFieldLogo: {
    marginBottom: "80px"
  },
  logo: {
    height: '107px',
    width: '188px',
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    width: '100%',
  },
  text: {
    textAlign: "center",
  },
  loginLogo: {
    textAlign: "center",
  },
  image: {
    backgroundImage: `url(${backgroundImg1.default})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    width: "40vw"
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerLogo: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  container: {
    width: "60vw",
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    maxWidth: 400,
  },
  submit: {
    textTransform: "none",
    backgroundColor: "#00C975",
    width: "100%",
    height: "56px",
    color: "white",
    font: 'inter',
    fontWeight: "700",
    borderRadius: "9px",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#00C975",
    }
  },
  link: {
    color: '#00C975',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: "700"
  },
};
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email format").required("Email field can’t be empty"),
  password: Yup.string().required("Password field can’t be empty").min(6, "Password must be at least 6 characters"),
});
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Grid container style={webStyle.root}>
        <Grid sx={webStyle.image} >
          <Box sx={webStyle.text}>
            <Box style={webStyle.textContainer}>
              <img style={webStyle.logo} src={whiteSurelyImg.default}></img>
            </Box>
            <Typography variant="h4" gutterBottom style={{ fontSize: "36px", fontWeight: 700 }}>
              Legal matters made easy
            </Typography>
            <Box style={{ display: "flex", textAlign: "center" }}>
              <Typography variant="body1" align="center" style={{ fontSize: "20px", fontWeight: 400, padding: "0 8rem" }}>
                Connecting law firms and clients for a hassle-free legal support and expert legal guidance.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid style={webStyle.container}>

          <Grid sx={webStyle.formContainer}>
            <img style={webStyle.textFieldLogo} src={blueSurelyImg.default}></img>
            <Typography variant="h5" style={{ fontSize: '24', fontWeight: 700, color: '#000000', marginBottom: "40px" }}>Sign in to Surely</Typography>
            <Formik
            data-test-id='formik'
              initialValues={{ email: "", password: "", rememberMe: false }}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <form style={webStyle.form} onSubmit={handleSubmit}>
                  <Box sx={{ marginBottom: "40px" }}>
                    <Typography style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Email</Typography>
                    <TextField
                      fullWidth
                      name="email"
                      placeholder="Your email"
                      value={values.email}
                      onChange={handleChange}
                      style={{ paddingBottom: "0.8rem", marginTop: "8px", width: "360px", height: "56px" }}
                    />
                    <Box style={{ width: "100%", color: "red", fontSize: "12px", fontWeight: 400 }}>
                      {touched.email && errors.email && errors.email}</Box>

                    <Typography style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Password</Typography>
                    <TextField
                      fullWidth
                      type={this.state.showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Your password"
                      value={values.password}
                      onChange={handleChange}
                      style={{ paddingBottom: "0.8rem", marginTop: "8px" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={this.handleClickShowPassword} edge="end">
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: { borderRadius: 2 },
                      }}
                    />
                    {touched.password && errors.password && (
                      <div style={{ color: "red", fontSize: "12px", fontWeight: 400 }}>{errors.password}</div>
                    )}

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="success"
                            name="rememberMe"
                            checked={values.rememberMe}
                            onChange={handleChange}
                          />
                        }
                        label="Remember me"
                      />
                      <Typography variant="body2" align="center">
                        <Typography style={webStyle.link}>Forgot password?</Typography>
                      </Typography>
                    </Box>
                  </Box>

                  <Button type="submit" sx={webStyle.submit}>
                    Login
                  </Button>

                  <Typography variant="body2" align="center" style={{ paddingTop: "2.5rem" }}>
                    Don't have an account? <span data-test-id='sign-up' onClick={this.goToSignupPage} style={webStyle.link}>Sign up</span>
                  </Typography>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
