// Customizable Area Start
import React from "react";
// Customizable Area Start
import {  Typography, Box,  Button  } from "@mui/material";
import OTPInputAuthController, { Props } from "./OTPInputAuthController";
import { backgroundImgs, logos, logos_2 } from "./assets";
// Customizable Area End

export default class OtpInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
     <Box sx={{ display: "flex", height: "100vh", width:"auto", flexDirection: {lx:"row",lg:"row",md:"row", sm:"column", xs:"column"} }}>
      {/* Left Side */}
      <Box
        sx={{
          flex:1,
          maxWidth:{md:"900px", lg:"600px"},
          boxSizing:"border-box",
          borderTopRightRadius:"16px",
          borderBottomRightRadius:"16px",
          backgroundImage: `url(${backgroundImgs.default})`,
          display:"flex",
          flexDirection:"column",
          justifyContent:"center",
          gap:"7rem",
          paddingTop:{xs:"40px", md:"91px"},
          paddingBottom:{xs:"40px", md:"91px"},
          paddingLeft:{xs:"40px", md:"64px"},
          paddingRight:{xs:"40px", md:"64px"},  
          width:"auto",   
        }}
      >
        <Box sx={{
            display:"flex",
            flexDirection:"column",
            gap:"3rem"
        }}>
        <img src={logos.default} alt="Surely Logo" style={{ width: "188px", height: "107px", marginBottom: "1rem" }} />
        <Box>
        <Typography variant="h4" sx={{ fontWeight: 700, mb: 2, color:"#FFFFFF", fontSize:"36px", lineHeight:"44px" }}>Welcome to Surely</Typography>
        <Typography sx={{color:"#FFFFFF", fontSize:"20px", fontWeight:400 }}>
          Finding the right help has never been easier! SurelyAI connects you with the most experienced attorneys
          precisely when you need them most.
        </Typography>    
        </Box>
        </Box> 
        <Box
          sx={{
            background: "#16686C",
            padding: {xs:"15px", md:"32px"},
            borderRadius: "24px",
            marginTop: "2rem",
            height:"218px",
            gap:"24px",
            display:"flex",
            flexDirection:"column",
            boxSizing:"border-box",
            maxWidth: {md:"650px",lg:"472px"},
            minWidth:"300px",
          }}
          >
           {this.state.testimonials.length > 0 && (
             <>
                <Typography  fontStyle="italic" fontSize="1.1rem" color="#ECFDF5">
                  {this.state.testimonials[this.state.selectedIndex]?.text}
                </Typography>
                <Box sx={{display:"flex", gap:"14px"}}>
                <img style={{objectFit:"cover", width:"38px", height:"38px",  borderRadius:"100%",}} 
                src={this.state.testimonials[this.state.selectedIndex]?.image.avatars.default} alt="Avatar" />
                <Box sx={{color:"#ECFDF5"}}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>{this.state.testimonials[this.state.selectedIndex]?.name}</Typography>
                <Typography variant="body2">{this.state.testimonials[this.state.selectedIndex]?.Occupation}</Typography>
                </Box>
                </Box>
             </>
            )}
         </Box>
         <Box sx={{position:"relative",bottom:"5rem", display:'flex',width:"auto", gap:"16px", marginX:"auto", justifyContent:"center", alignItems:"center"}}>
                {this.state.testimonials.map((_, index) => (
                  <button
                  data-test-id="slidBtn"
                  style={{
                    border: "none",
                    borderRadius: "100%",
                    height: this.state.selectedIndex === index ? "12px" : "8px",
                    width: this.state.selectedIndex === index ? "12px" : "8px",
                    backgroundColor: this.state.selectedIndex === index ? "#047857" : "#D9D9D9"
                  }}
                  key={index}
                  onClick={() => this.setState({ selectedIndex: index })}
                >
                </button>
                ))}
            </Box>
          </Box>

      {/* Right Side */}
  <Box
  sx={{
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: { xs: "2rem", sm: "2rem", md: "3rem" },
    background: "white",
    boxSizing: "border-box"
  }}
>
  {this.state.showEmailVerification ? (
    <Box sx={{ textAlign: "center", maxWidth: "400px" }}>
        <Box sx={{paddingY:"4rem"}}>
            <img src={logos_2.default} alt="Surely Logo" style={{ width: "150px", height: "55px", margin: "auto"}} />
        </Box>
     <Box sx={{textAlign:"left"}}>
     <Typography variant="h5" sx={{ fontWeight: "bold", mt: 3 }}>Email verification</Typography>
      <Typography sx={{ mt: 1 }}>
        We have sent you a verification link. Please check your email <b>Hu*****@gmail.com</b>
      </Typography>
     </Box>
      <Button 
        sx={{
          mt: 3,
          width: "100%",
          maxWidth: "397px",
          height: "56px",
          borderRadius: "8px",
          border: "1px solid #CBD5E1",
          backgroundColor: "#FFFFFF",
          color: "#1E293B",
          cursor: "pointer",
          fontSize: "16px"
        }}
      >
        <Typography sx={{ fontWeight: "700", textTransform:"none" }}>Resend verification link</Typography>
      </Button>
    </Box>
  ) : (
    <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "600px" }}>
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{paddingY:"4rem"}}>
            <img src={logos_2.default} alt="Surely Logo" style={{ width: "150px", height: "55px",   }} />
        </Box>
        <Box sx={{textAlign:"left"}}>
        <Typography variant="h5" sx={{ fontWeight: "bold", mt: 3 }}>Account verification</Typography>
        <Typography sx={{ mt: 1 }}>
          Verification code sent to your phone number.<br />
          Please check your phone messages.
        </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "5px", mt: 2 }}>
        {this.state.otpFields.map((value:any, index:any) => (
          <Box
            component="input"
            type="text"
            id={`otp-${index}`}
            key={index}
            value={value}
             data-test-id="setOtpBtn"
             ref={this.ref[index]}
            onKeyDown={(event:any) => this.handleKeyDown(index, event)}
            maxLength={1}
            sx={{
              color: "#1E293B",
              width: "56px",
              height: "56px",
              textAlign: "center",
              fontSize: "18px",
              border: "1px solid #CBD5E1",
              borderRadius: "8px",
              outline: "none",
              fontWeight: 700,
              '@media (max-width: 400px)': {
                width: "50px",
                height: "56px",
                fontSize: "14px",
              },
            }}
          />
        ))}
      </Box>

      <Box sx={{display:"flex", alignItems:"center", paddingTop:"5px",}} >
     <Typography style={{ display:"flex", color: "#1E293B", fontSize:"16px", whiteSpace: "nowrap"}} >You can send another code in </Typography>
        <Typography sx={{paddingX:"4px", color:"#0D176C", fontWeight:400}}> 58:62 </Typography>
        <Typography>seconds</Typography>
     </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", mt: 3 }}>
        <Button
          sx={{ textTransform: 'none' }}
          style={{
            width: "100%",
            maxWidth: "360px",
            height: "56px",
            borderRadius: "8px",
            backgroundColor: "#00C975",
            color: "#FFFFFF",
            fontSize: "16px"
          }}
        >
          <Typography sx={{ fontWeight: "700" }}>Resend verification code</Typography>
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          data-test-id="emailBtn"
          onClick={this.handleSendEmailVerification}
          style={{
            width: "100%",
            maxWidth: "360px",
            height: "56px",
            borderRadius: "8px",
            border: "1px solid #CBD5E1",
            backgroundColor: "#FFFFFF",
            color: "#1E293B",
            cursor: "pointer",
            fontWeight:600,
            fontSize: "16px"
          }}
        >
          <Typography sx={{ fontWeight: "700" }}>Send an email verification</Typography>
        </Button>
      </Box>
    </Box>
  )}
</Box>
    </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area End
