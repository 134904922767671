
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { avatar } from "./assets";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
export const configJSON = require("./config.js");

export interface Props {
 
}

interface S {
  selectedCountry:string;
  selectedCity:string;
  selectedyear:string;
  showIdentifyUI:boolean;
  successMessage:boolean;
  explore:boolean
  filteredCities: { id: number; name: string }[];
  testimonials : [
    {
       text: string,
       name:string,
       Occupation: string,
       image:any
    },
    {
      text: string,
      name:string,
      Occupation: string,
      image:any
    },
    {
      text: string,
      name:string,
      Occupation: string,
      image:any
    }
];
selectedIndex:any;
countryData: {
  success: boolean;
  data: {
    countries: {
      id: number;
      name: string;
      flag: string;
    }[],
    cities:{
      country_id:number,
      cities:[
        {
          id:number,
          name:string
        }]
    }[],
    years_in_practice:{
      id:number;
      name:string;
    }[];
  },
};
}

interface SS {
 
}

export default class MultipageFormsController extends BlockComponent<Props, S, SS> {
  getCountryDropDownCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      selectedCountry:"",
      selectedCity:"",
      selectedyear:"",
      filteredCities: [],
      testimonials : [
        {
           text: "I had used surely for all of my legal issues I can't recommend it enough for anyone who needs legal advice",
           name:'Salma Hussain',
           Occupation: 'School teacher',
           image:{avatar}
        },
        {
            text: "I for anyone who of my legal issues I can't recommend it enough for anyone who needs legal advice",
            name:'Vishal Rajput',
            Occupation: 'School principal',
            image:{avatar}
        },
        {
            text: "I for anyone who my legal issues I can't recommend it enough for anyone who needs legal advice",
            name:'Gaurav Thakur',
            Occupation: 'Trusty',
            image:{avatar}
        }
    ],
    selectedIndex:0,
    countryData: {
      success: false,
      data: {
        countries: [],
        cities: [],
        years_in_practice:[],
      }
    },
    showIdentifyUI: false,
    successMessage: false,
    explore:false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getCountryDropDown()
  }
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.getCountryDropDownCallId == apiRequestCallId) {
        this.setState({countryData:responseJson})
        console.log("responseJson===",responseJson);
      }
    }
  }

  handleCountryChange = (event: any) => {
    const selectedCountry = event.target.value;
    const selectedCountryData = this.state.countryData.data.countries.find(
      (country) => country.name === selectedCountry
    );

    if (selectedCountryData) {
      const filteredCities =
        this.state.countryData.data.cities.find((city) => city.country_id === selectedCountryData.id)?.cities || [];
      this.setState({
        selectedCountry,
        selectedCity: "",
        filteredCities,
      });
    }
  };

  handleCityChange = (event:any) => {
    this.setState({ selectedCity: event.target.value });
  };

  handleYearChange = (event:any) => {
    this.setState({ selectedyear: event.target.value });
  };

  handleNextClick = () => {
    this.setState({ showIdentifyUI: true });
  };

  handleSuccessClick = () => {
    this.setState({ successMessage: true });
  };

  handleExplore = () => {
    this.setState({ explore: true });
  };

  getCountryDropDown = () => {
    const headers = {
      "content-type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryDropDownCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/dropdowns?language=en`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}